<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-icon color="primary" x-large>mdi-power-plug-off</v-icon>
          <h4 class="mt-5">
            {{ $store.getters.translate("metabase_configuration_incorrect") }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../components/commonComponents/BaseBreadcrumb";

export default {
  components: {
    BaseBreadcrumb,
  },
  name: "Dashboard",
  data: () => ({
    page: {
      title: "Dashboard",
      name: "dashboard",
    },
  }),
  mounted() {
  },
  methods: {
  },
  watch: {
  },
};
</script>